Vue.component('price', {
  template: `
  <span>{{price_string}}{{currency_string}}</span>
  `,
  props: [
    'price',
    'currency'
  ],
  computed: {
    price_string: function () {
      var price = Number(this.price).toFixed(2);
      return this.formatNumber(price);
    },
    currency_string: function () {
      if(this.currency === 'false' || this.currency === false) {
        return '';
      } else if (!this.currency) {
        return ' CHF';
      } else {
        return ' ' + this.currency;
      }
    }
  },
  methods: {
    formatNumber: function (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    }
  }
})